<template>
  <div>
    <v-modal-users-insert v-model="dialogInsert"></v-modal-users-insert>
    <v-modal-users-delete :id="selectedIndex" v-model="dialogDelete"></v-modal-users-delete>
    <v-modal-users-update :id="selectedIndex" v-model="dialogUpdate"></v-modal-users-update>
    <v-modal-users-gift :id="selectedIndex" v-model="dialogGift"></v-modal-users-gift>
    <v-modal-users-resend-invite :id="selectedIndex" v-model="dialogResendInvite"></v-modal-users-resend-invite>

    <v-page-title title="Users">
      <v-btn elevation="0" color="blue-grey darken-4" small dark @click="dialogInsert = true">
        <v-icon left small>mdi-plus</v-icon>
        New User
      </v-btn>
    </v-page-title>

    <v-card max-width="1200px" class="mx-auto my-5" elevation="0" color="transparent">
      <v-container>
        <v-row dense>
          <v-col>
            <v-loader v-if="!users"></v-loader>
            <div class="hounddog-table" v-else>
              <v-data-table
                :headers="headers" 
                :items="users" 
                :items-per-page=30
                item-key="id"
                elevation="0"
                no-data-text="Loading..."
                :mobile-breakpoint="0"
                :expanded.sync="expanded"
                show-expand
              >
                <template v-slot:item.active="{ item }">
                  <div v-if="item.super == 0">
                    <v-switch
                      style="zoom: 0.8"
                      :input-value="item.active"
                      inset
                      :true-value="1"
                      :false-value="0"
                      hide-details
                      @change="toggleUser(item.id)"
                    ></v-switch>
                  </div>
                </template>
                <template v-slot:item.email="{ item }">
                  <b>{{ item.email }}</b>
                </template>
                <template v-slot:item.created_at="{ item }">
                  <b>{{ $date.fullDateTime(item.created_at) }}</b>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div v-if="item.super == 0">
                    <v-btn-toggle mandatory background-color="blue-grey lighten-1" class="ml-2">
                      
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small icon color="blue-grey lighten-1" v-bind="attrs" v-on="on" dark @click="resendInviteDialog(item)" :disabled="item.active == 0">
                            <v-icon small color="white">mdi-mail</v-icon>
                          </v-btn>
                        </template>
                        <span>Resend Email</span>
                      </v-tooltip>
                      
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small icon color="blue-grey lighten-1" v-bind="attrs" v-on="on" dark @click="giftCreditsDialog(item)" :disabled="item.active == 0">
                            <v-icon small color="white">mdi-gift</v-icon>
                          </v-btn>
                        </template>
                        <span>Gift Credits</span>
                      </v-tooltip>

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small icon color="blue-grey lighten-1" v-bind="attrs" v-on="on" dark @click="updateDialog(item)">
                            <v-icon small color="white">mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit this</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small icon color="blue-grey lighten-1" v-bind="attrs" v-on="on" dark @click="deleteDialog(item)">
                            <v-icon small color="white">mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete this</span>
                      </v-tooltip>
                    </v-btn-toggle>
                  </div>
                  <v-chip x-small v-if="item.super == 1" class="mx-3">
                    Super
                  </v-chip>
                </template>
                <template v-slot:no-data>
                  Loading...
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="px-0">
                    <v-simple-table dark class="no-hover">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th>Total Credits</th>
                            <th>Total Scans</th>
                            <th>Remaining Scans</th>
                            <th>Total Logins</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{{item.total_credits}}</td>
                            <td>{{item.total_scans}}</td>
                            <td>{{item.remaining_credits}}</td>
                            <td>{{item.total_logins}}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </td>
                </template>
              </v-data-table>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>     
  </div>
</template>

<script>
  export default {
    name: 'Users.vue',
    data() {
      return {
        expanded: [],
        selectedIndex: 0,
        dialogInsert: false,
        dialogDelete: false,
        dialogUpdate: false,
        dialogResendInvite: false,
        dialogGift: false,

        dialog: false,
        defaultItem: null,
        headers: [
          { text: "ID", value: "id", width: '8%', divider: true },
          { text: "Name", value: "name", width: '20%', divider: true },
          { text: "Email", value: "email", width: '25%', divider: true },
          { text: "Created On", value: "created_at", width: '20%', divider: true },
          { text: '', value: 'actions', sortable: false, width: '15%', align:'center', divider: true },
          { text: "Active", value: "active", sortable: true, width: '8%', align: 'center', divider: true },
        ],
      }
    },
    computed: {
      users(){
        return this.$store.getters['users/all'];
      },
    },
    methods: {      
      updateDialog (item) {
        this.selectedIndex = item.id;
        this.dialogUpdate = true;
      },
      deleteDialog (item) {
        this.selectedIndex = item.id;
        this.dialogDelete = true;
      },
      resendInviteDialog (item) {
        this.selectedIndex = item.id;
        this.dialogResendInvite = true;
      },
      giftCreditsDialog(item){
        this.selectedIndex = item.id;
        this.dialogGift = true;
      },
      async toggleUser(id){
        await this.$store.dispatch("users/toggle", {id: id});
      },
    },
  }
</script>

<style lang="scss">
// .v-data-table__wrapper{
//   table{
//     tr{
//       td{
//         font:{
//           size: 14px !important;
//         }
//       }
//     }
//   }
// }
</style>